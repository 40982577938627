<template>
  <div class='v-timer' v-if="!timeObj.cancel">
    <p class="title">倒计时自动保存表格</p>
    <div class="time-wrap">
      <svg t="1609409589509" v-if="formType()=='hti'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13176" width="14" height="14"><path d="M512 0a512 512 0 1 0 0 1024A512 512 0 0 0 512 0z m236.251429 590.774857H512a39.350857 39.350857 0 0 1-39.350857-39.424V236.324571a39.350857 39.350857 0 1 1 78.701714 0V512h196.973714a39.350857 39.350857 0 0 1 0 78.774857z" p-id="13177" fill="#00C8CB"></path></svg>
      <svg t="1609409589509" v-if="formType()!=='hti'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13176" width="14" height="14"><path d="M512 0a512 512 0 1 0 0 1024A512 512 0 0 0 512 0z m236.251429 590.774857H512a39.350857 39.350857 0 0 1-39.350857-39.424V236.324571a39.350857 39.350857 0 1 1 78.701714 0V512h196.973714a39.350857 39.350857 0 0 1 0 78.774857z" p-id="13177" fill="#fd7923"></path></svg>
      <span class="time" :class="{'hti-time':formType()=='hti'?true:false}">{{timeTxt}}</span>
      <i v-if="!timeObj.pause" class="operate el-icon-video-pause" @click="pauseTick"></i>
      <i v-else class="operate el-icon-video-play" @click="startTick"></i>
      <i class="operate el-icon-refresh-left" @click="resetTick"></i>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['hideSubmitBtn','formType'],
  tickTime: '',
  data(){
    return {
      timeObj: {
        value: 300,
        pause: false,
        cancel: false,
      },
    }
  },
  computed:{
    timeTxt(){
      let second = this.timeObj.value % 60;
      second.toString().length == 1 && (second = '0' + second);
      let minute = Math.floor(this.timeObj.value / 60);
      minute.toString().length == 1 && (minute = '0' + minute);
      return minute + ':' + second
    },
    getHideSubmitBtn(){
      return this.hideSubmitBtn();
    }
  },
  watch: {
    getHideSubmitBtn: {
      handler(val){
        val? this.closeTick(): this.startTick()
      },
      immediate: true
    }
  },
  methods:{
    resetTick(){
      this.timeObj.value = 300;
      this.startTick();
    },
    startTick(){
      this.timeObj.pause = false;
      this.tickTime && clearInterval(this.tickTime)
      this.tickTime = setInterval(()=>{
        if(this.timeObj.pause){
          clearInterval(this.tickTime)
        }
        if(this.timeObj.value == 0) {
          this.$emit('tick-is-zero');
          clearInterval(this.tickTime)
          return;
        }
        this.timeObj.value --;
      }, 1000)
    },
    pauseTick(){
      this.timeObj.pause = true;
      clearInterval(this.tickTime)
    },
    closeTick(){
      this.timeObj.cancel = true;
      this.pauseTick();
    },
  }
}
</script>

<style lang='scss' scoped>
.v-timer {
  height: auto;
  position: relative;
  transition: height .3s ease;
  overflow: hidden;
  width: 180px;
  .el-icon-error {
    color: var(--form-color-primary);
    opacity: 0;
    cursor: pointer;
    width: 0px;
    height: 0px;
    position: absolute;
    top: 12px;
    left: 0;
  }
  .icon-wrap {
    border-radius: 50%;
    display: flex;
    font-size: 14px;
    align-items: center;
    line-height: 20px;
  }
  .title {
    font-size: 12px;
    color: rgba(0, 0, 0, .45);
    margin: 6px 0;
    text-align: left;
  }
  .time-wrap {
    display: flex;
    align-items: center;
    padding-right: 12px;
    .time {
      color: var(--form-color-primary);
      font-weight: bold;
      font-size: 22px;
      cursor: default;
      flex-grow: 1;
      margin-left: 4px;
      text-align: left;
    }
    .operate {
      font-size: 16px;
      color: #aaa;
      cursor: pointer;
      margin-left: 6px;
      transition: all .2s ease;
      &.el-icon-video-play{
        color: var(--form-color-primary);
      }
      &:hover {
        color: var(--form-color-primary);
      }
    }
  }
  &:hover{
    .el-icon-error {
      opacity: 1;
      transition: all .3s ease;
      width: 14px;
      height: 14px;
    }
  }
}
@media screen and (max-width: 750px) {
  .v-timer{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    background: #f1f1f1;
    border-top: 1px solid #fff;
    .el-icon-error{
      position: static;
      opacity: 1;
      right: 0;
      top: 0;
      width: 14px;
      text-align: left;
      margin-left: 12px;
    }
    .title{
      margin: 0 12px;
    }
    .time-wrap{
      flex: 1;
    }
  }
}
</style>