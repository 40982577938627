var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"note-book"},[_c('div',{staticClass:"mask-wrap"},[_c('button',{staticClass:"close-btn",on:{"click":_vm.close}},[_vm._v("我已了解，关闭")]),_c('h2',[_vm._v("填写指引")]),_c('ul',{staticClass:"steps"},_vm._l((_vm.arr),function(item,key){return _c('li',{key:key,style:({opacity: _vm.step == key? 1: 0}),domProps:{"innerHTML":_vm._s(item)}})}),0),_c('div',{staticClass:"step-btn"},[_c('button',{class:['next-btn', _vm.step == 0 && 'disabled'],on:{"click":_vm.handlePrev}},[_vm._v("上一个")]),_c('div',{staticClass:"page"},[_vm._v("("+_vm._s(_vm.step+1+'/'+_vm.arr.length)+")")]),_c('button',{class:['next-btn', _vm.step == _vm.arr.length - 1 && 'finished'],on:{"click":_vm.handleNext}},[_vm._v(_vm._s(_vm.step == _vm.arr.length - 1? '完成': '下一个'))])])]),_c('div',{staticClass:"mg"}),_c('div',{staticClass:"center"},[_c('div',{staticClass:"side"},[_c('div',{staticClass:"place",style:({opacity: 1})}),_c('div',{staticClass:"tit1",style:({
          opacity: _vm.step == 0 || _vm.step == 1? .1: 1
        })}),_c('div',{staticClass:"tit2",style:({
          opacity: _vm.step == 2? .1: 1
        })}),_c('div',{staticClass:"tit3",style:({
          opacity: _vm.step == 3? .1: 1
        })}),_c('div',{staticClass:"tit4"}),_c('div',{staticClass:"tit5",style:({
          opacity: _vm.step == 10? 1: 0
        })}),_c('div',{staticClass:"tit6",style:({
          opacity: _vm.step == 11? 1: 0
        })})]),_c('div',{staticClass:"title"},[_c('div',{staticClass:"mg",style:({flex: 1})}),_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"tit1",style:({
            opacity: _vm.step == 4? .1: 1
          })}),_c('div',{staticClass:"tit2",style:({
            opacity:  _vm.step == 5? .1: 1
          })}),_c('div',{staticClass:"tit3",style:({
            opacity: _vm.step == 6? .1: 1
          })})])]),_c('div',{staticClass:"cnt"},[_c('div',{staticClass:"tit1",style:({
          opacity: _vm.step == 7? .1: 1
        })}),_c('div',{staticClass:"tit2",style:({
          opacity: _vm.step == 8 || _vm.step == 9 ? .4: 1
        })})])]),_c('div',{staticClass:"mg"})])
}
var staticRenderFns = []

export { render, staticRenderFns }