<template>
  <div class="language">
    <span :class="['lan', {active: language === 'zh'}]" @click="handleSetLanguage('zh')">中 </span>
    <span class="lan">/</span>
    <span :class="['lan', {active: language === 'zh-hk'}]" @click="handleSetLanguage('zh-hk')"> 繁 </span>
    <span class="lan">/</span>
    <span :class="['lan', {active: language === 'en-us'}]" @click="handleSetLanguage('en-us')"> En</span>
  </div>
</template>

<script>
export default {
  props: {
    language: {
      type: String,
      default: 'zh'
    }
  },
  methods: {
    handleSetLanguage(lang) {
      document.documentElement.lang = lang;
      this.$emit('set-lang', lang);
    }
  }
}
</script>
<style lang="scss" scoped>
.el-icon-caret-bottom{
  font-size: 14px;
}
.language {
  cursor: pointer;
  .lan {
    font-size: 14px;
    color: #333333;
    &.active {
      color: #fff;
    }
  }
}
</style>
